<template>
  <section class="m-gift-cell m-padding">

      <router-link  :to="to" class="gift-cell-link">

        <div class="m-cell">
          <div class="m-cell-title">
            <img src="@/assets/gift/zhuangshi@2x.png" alt="">
            <span>{{title}}</span>
          </div>
          <div class="m-cell-value">
            <span>{{value}}</span>
            <img src="@/assets/gift/icon_genduo@2x.png" alt="more">
          </div>
        </div>
        
      </router-link>

  </section>
</template>

<script>
export default {
  name:'IndexGrid',
  props:['title', 'value', 'to',  ]
}
</script>

<style lang="less" scoped>
.m-gift-cell {
  .m-cell {
    display: flex;
    width: 100%;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    .m-cell-title {
      font-size:16px;
      font-weight:600;
      color:#fff;
      line-height:25px;
      letter-spacing: 0.47px;
      display: flex;
      align-items: center;
      &>img {
        width: 12px;
        height: 16px;
        margin-right: 10px;
      }
    }
    .m-cell-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      font-size:12px;
      font-weight:400;
      color:#fff;
      line-height:20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>