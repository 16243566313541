<template>
  <section class="m-gift m-scroll" ref="scroll"  @scroll="handleScroll($event)" :style="{'background-color': bgColor}">
    <!-- 头部导航 -->
    <div class="header" :style="`background:rgba(255,255,255,${(this.offsetTop-150) / 150});`">
      <img @click="handleBack" src="@/assets/goods/icon_back_2@2x.png" alt="back">
      <!-- <div class="search" :style="`background:${(this.offsetTop >= 150 ? '#f1f1f1' : '#fff')};`"
        @click="$router.push('/zh/search?s=gift')">
        <img src="@/assets/gift/icons_search2@2x.png" alt="">
      </div> -->
      <van-search v-model="searckKey" placeholder="请输入关键词" autofocus clearable
      @search="onSearch" shape="round" background="transparent"></van-search>
      <router-link to="/zh/cart" >
        <img src="@/assets/goods/cart.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};background-image: url(${bannerList[0].pic_url})`"
    @click="goToLink(bannerList[0].link)"></div>

    <!-- 头部大图 -->
    <div class="classify m-padding">
      <div class="grid-2">
          <div @click="goToLink(bannerList[1].link)"><img :src="bannerList[1].pic_url" alt="img"></div>
          <div @click="goToLink(bannerList[2].link)"><img :src="bannerList[2].pic_url" alt="img"></div>
      </div>
      <div class="grid-1">
          <div @click="goToLink(bannerList[3].link)"><img :src="bannerList[3].pic_url" alt="img"></div>
      </div>
    </div>

    <!-- 滚动切换 -->
    <van-tabs  scrollspy :offset-top="tabTop" sticky swipeable title-active-color="#ED2A24" title-inactive-color="#000000" style="z-index:10">
      <van-tab :title="item.title" v-for="(item, index) in classifyList" :key="index">
        <gift-cell :title="item.title" value="更多"  :to="`/zh/goods-list/${item.id}?title=${item.title}&id=${$route.query.id}`" class="title"></gift-cell>
        <goods-columns @cartClick="handleCart" :data="goodsList[index]" :columns="item.row_pro_num"></goods-columns>
      </van-tab>
    </van-tabs>

    <!-- <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading> -->

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>

  </section>
</template>

<script>
import GiftCell     from '@/components/zh/gift-cell.vue'
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getGiftClassify, getGiftClassifyGoods, getGiftBanner, getGiftColor } from '@/api/zh/mooby.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ GiftCell, MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      tab:0,          // tabs选中的面板
      recomnd:4,
      loading:false,
      skuModal:false,
      start:false,
      start_dom:null,
      tabTop:44,
      dotAnimate:false,
      cartNum:0,
      scrollTop:false,
      classifyList: [],
      goodsList: [],
      activeSectionId: '',
      noMore: false,
      bgColor: '',
      bannerList: [{},{},{},{}],
      searckKey: '',
      goodsId: ''
    }
  },

  mounted(){
    this.getGiftClassifyHandle()
    this.getGiftBannerHandle()
    this.getGiftColorHandle()

    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
    localStorage.setItem('_back', '/')
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
    },
    handleBack(){
      this.$router.go(-1)
    },

    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getGiftBannerHandle() {
      // 获取自营专区Banner
      getGiftBanner(this.$route.query.id).then(res => {
        if(res) {
          this.bannerList = res.data
        }
      })
    },
    getGiftColorHandle() {
      // 获取自营专区Color
      getGiftColor(this.$route.query.id).then(res => {
        if(res) {
          this.bgColor = res.data.color
        }
      })
    },
    getGiftClassifyHandle() {
      // 获取自营专区分类
      getGiftClassify(this.$route.query.id).then(res => {
        if(res) {
          this.classifyList = res.data.filter(item => {
            return item.status == 1
          })
          this.classifyList.forEach((el, index) => {
            this.getGiftClassifyGoodsHandle(el.id, index)
          })
        }
      })
    },
    getGiftClassifyGoodsHandle(id, index) {
      // 获取分类下的商品
      getGiftClassifyGoods({type_id: id, page: 1, cid: this.$route.query.id}).then(res => {
        if(res) {
          this.$set(this.goodsList, index, res.data.data)
        }
      })
    },
    onSearch() {
      // 前往搜索页面
      localStorage.setItem('back_path', this.$route.fullPath)
      this.$router.push(`/zh/search?s=gift&key=${this.searckKey}`)
    },
    goToLink(link) {
      if(link.indexOf('http') != -1) {
        window.location.href = link
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './gift.less';
</style>
